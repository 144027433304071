import {generateRoute} from '@/function'
import updateUrl from '@/mixins/updateUrl'

import CONSTANTS from '@/const/'
import ROUTE_NAME from '@/router/routeName.js'

import selectTreeSegments from '../selectTreeSegments/'

export default {
  mixins: [
    updateUrl
  ],

  components: {
    'v-select-tree-segments': selectTreeSegments
  },

  props: {
    searchEngines: {
      type: Array,
      default: function () {
        return []
      }
    },

    updates: {
      type: Object,
      default: function () {
        return {}
      }
    },

    salesMetrics: {
      type: Array,
      default: function () {
        return []
      }
    },

    urlFilters: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  data () {
    let contentPathFilters = 'services.bi.comparison.filters'

    return {
      CONSTANTS,
      ROUTE_NAME,
      contentPathFilters,

      filters: {
        search_system: undefined,
        category_id: undefined,
        group_id: undefined,
        query_group_id: undefined,
        visibility: undefined,
        date: {
          from: undefined,
          to: undefined
        },
        all_site: false,
        metric: undefined
      },

      filtersCG: {
        category_id: undefined,
        group_id: undefined,
        query_group_id: undefined
      }
    }
  },

  computed: {
    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.projectId
      return id ? +id : undefined
    },

    biLink () {
      return {
        name: ROUTE_NAME.BI_SEGMENTS,
        params: {
          activeProject: this.activeProjectId
        },
        query: {
          search_system: this.filters.search_system,
          category_id: this.filters.category_id,
          group_id: this.filters.group_id,
          query_group_id: this.filters.query_group_id,
          visibility: this.filters.visibility
        }
      }
    },

    types () {
      let newRoute = generateRoute(this.$route)
      return [
        {
          name: this.$t(this.contentPathFilters)['Dynamics'],
          to: {
            ...newRoute,
            name: ROUTE_NAME.BI_COMPARISON_DYNAMICS_SALES
          }
        },
        {
          name: this.$t(this.contentPathFilters)['Correlations'],
          to: {
            ...newRoute,
            name: ROUTE_NAME.BI_COMPARISON_CORRELATION_SALES
          }
        }
      ]
    },

    typeDynamics () {
      return this.$route.name === ROUTE_NAME.BI_COMPARISON_DYNAMICS_SALES
    }
  },

  methods: {
    setFilters (filters) {
      this.$set(this, 'filters', {
        ...filters
      })

      const { kg_category_id, kg_group_id, kg_query_group_id } = filters

      this.$set(this, 'filtersCG', {
        category_id: kg_category_id,
        group_id: kg_group_id,
        query_group_id: kg_query_group_id
      })
    },

    /* Изменение URL фильтров */
    updateFilters (filter, data) {
      if (filter === 'date') {
        this.updateUrl({
          date_from: data.from,
          date_to: data.to
        })
      } else {
        this.updateUrl({[filter]: data})
      }
    },

    updateFiltersCG (query) {
      this.updateUrl({
        kg_category_id: query.category_id,
        kg_group_id: query.group_id,
        kg_query_group_id: query.query_group_id
      })
    }
  },

  created () {
    this.setFilters(this.urlFilters)
  },

  watch: {
    urlFilters (value) {
      this.setFilters(value)
    }
  }
}
