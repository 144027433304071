<template>
  <li>
    <div
      :class="['tree-node__title', { '_active': isActive }]"
      :style="`padding-left: ${level * 20 + 12}px`"
      v-on:click="click(data)"
    >
      {{ data.name }}
    </div>
  </li>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'v-tree-node',

  props: {
    /* Значение */
    data: {
      type: [Object],
      default: null
    },

    /* Уровень вложенности */
    level: {
      type: Number,
      default: 1
    },

    /* Список выбранных опций */
    value: {
      type: [Array, Number, String]
    },

    /* Множественный выбор */
    multiple: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isActive () {
      if (this.multiple) {
        return this.value && this.value.indexOf(this.data.segment_type_id) !== -1
      } else {
        return this.value && this.value == this.data.segment_type_id
      }
    }
  },

  created () {
    if (this.isActive) {
      this.$emit('open')
    }
  },

  methods: {
    click (data) {
      this.$emit('click', data)
    }
  }
}
</script>
