<template>
  <v-page-title :primary="primary"
                :secondary="secondary"
                :projectData="projectData"
                :intercomId="intercomId"
                :breadcrumbs="breadcrumbs"
                :breadcrumbsHome="breadcrumbsHome"
                :customRouteList="customRouteList"
  >
    <template #additional v-if="projectData && projectData.properties">
      <div class="title__project-info">
        <div class="title__project-info-item title__project-device" v-if="projectData.properties.is_mobile">
          <icon name="phone_iphone" fill="#BDBDBD"/>
          {{ $t(contentPath)['Mobile'] }}
        </div>
        <div class="title__project-info-item title__project-device" v-else>
          <icon name="laptop" fill="#BDBDBD"/>
          {{ $t(contentPath)['Desktop'] }}
        </div>
        <div class="title__project-info-item title__project-region" id="title-project-region">
          <icon name="location_on" fill="#BDBDBD"/>
          {{ projectData.regions[projectData.properties.default_search_engine].name }}
        </div>
      </div>
    </template>

    <template #tooltip>
      <slot name="tooltip"></slot>
    </template>
  </v-page-title>
</template>

<script>
export default {
  props: {
    primary: {
      type: String,
      default: ''
    },

    secondary: {
      type: String,
      default: ''
    },

    intercomId: {
      type: [String, Number],
      default: 0
    },

    breadcrumbs: {
      type: Boolean,
      default: false
    },

    customRouteList: {
      type: Array
    },

    breadcrumbsHome: {
      type: Boolean,
      default: false
    },

    projectData: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    contentPath: 'modules.deviceTypes'
  })
}
</script>

<style lang="scss" scoped>
  .title {
    &__project-info {
      height: 28px;
      display: flex;
      border-left: 1px solid #E0E6F0;
      padding: 0 12px;
    }

    &__project-info-item {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }

    &__project-device {
      padding-right: 12px;
    }

    &__project-region {
      border-left: 1px solid #E0E6F0;
      padding-left: 12px;
      margin-right: 12px;
    }
  }
</style>
