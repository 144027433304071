import {mapState, mapGetters, mapActions} from 'vuex'
import CONSTANTS from '@/const/'
import treeNode from './treeNode.vue'

export default {
  components: {
    'v-tree-node': treeNode
  },

  props: {
    /* Значение */
    filters: {
      type: Object,
      default: () => {
        return {}
      }
    },

    /* Внутренний лейбл */
    insideLabel: {
      type: String
    },

    /* Плйсхолдер */
    placeholder: {
      type: String
    },

    /* Множественный выбор */
    multiple: {
      type: Boolean,
      default: true
    },

    /* Значение */
    segmentTypes: {
      type: Array,
      default: () => {
        return [
          CONSTANTS.SEMANTIC_SECTIONS.CATEGORY,
          CONSTANTS.SEMANTIC_SECTIONS.GROUP,
          CONSTANTS.SEMANTIC_SECTIONS.QUERY_GROUP
        ]
      }
    },

    theme: {
      type: String,
      default: 'default'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      /* Значение фильтра */
      guiValue: [],

      /* Прелодер */
      preloader: false,

      /* Поле поиска */
      search: ''
    }
  },

  computed: {
    ...mapState({
      catalog: state => state.segments.catalog.dataSW,
      segments: state => state.segments.catalog.segments
    }),

    ...mapGetters({
      nestedCatalogSegments: 'segments/catalog/nestedCatalogSegmentsSW'
    }),

    nestedCatalogSegmentsFiltered () {
      return this.nestedCatalogSegments({
        search: this.search
      })
    },

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.projectId
      return id || undefined
    }
  },

  created () {
    this.init()
  },

  watch: {
    filters (value) {
      this.setValue(this.filters)
    }
  },

  methods: {
    ...mapActions({
      getCatalog: 'segments/catalog/getCatalogSW',
      getAllSegments: 'segments/catalog/getAllSegments'
    }),

    async init () {
      this.preloader = true

      this.setValue(this.filters)

      await this.getCatalog({
        query: {
          project_id: this.activeProjectId,
          skip_regionality: 1
        }
      })

      await this.getAllSegments({
        types: this.segmentTypes,
        query: {
          project_id: this.activeProjectId,
          skip_regionality: 1
        }
      })

      this.preloader = false
    },

    setValue (filters) {
      let values = this.multiple ? [] : null

      const generateValues = ({ type, ids }) => {
        if (this.multiple) {
          if (ids && Array.isArray(ids) && ids.length) {
            ids = ids.map(i => JSON.stringify({ id: +i, type: type }))
            values = values.concat(ids)
          }
        } else {
          if (ids) {
            values = JSON.stringify({ id: +ids, type: type })
          }
        }
      }

      for (let type of this.segmentTypes) {
        if (filters && filters[`${type}_id`]) {
          generateValues({
            type: type,
            ids: filters[`${type}_id`]
          })
        }
      }

      this.$set(this, 'guiValue', values)
    },

    /* Изменение URL фильтров */
    updateFilters (value) {
      let filters = this.multiple ? {
        category_id: [],
        group_id: [],
        query_group_id: []
      } : {}

      if (this.multiple) {
        for (let item of value) {
          try {
            let filter = JSON.parse(item)
            filters[`${filter.type}_id`].push(filter.id)
          } catch (_) {
          }
        }
      } else {
        try {
          let filter = JSON.parse(value)
          filters[`${filter.type}_id`] = filter.id
        } catch (_) {
        }
      }

      for (let i in filters) {
        if (filters[i].length === 0) {
          filters[i] = undefined
        }
      }

      this.updateUrl(filters)
    },

    /* Обновление URL фильтров */
    updateUrl (query) {
      this.$emit('update-url', query)
    }
  }
}
