import {mapState, mapActions, mapGetters} from 'vuex'
import CONSTANTS from '@/const/'

import {findOptions, nearestDate} from '@/function'

import pageProjectTitle from '../components/pageProjectTitle/pageProjectTitle'
import error from '@/services/errors/block.vue'
import container from '@/services/components/container/'
import filters from '../components/filters/'
import updateUrl from '@/mixins/updateUrl'

export default {
  mixins: [
    updateUrl
  ],

  components: {
    'v-page-project-title': pageProjectTitle,
    'v-container': container,
    'v-filters': filters,
    'v-error': error
  },

  data: () => {
    const contentPath = 'services.bi.comparison'
    const contentPathFilters = 'services.bi.comparison.filters'

    return {
      contentPath,
      contentPathFilters,

      preloader: false,

      updates: {
        has_data: false,
        items: [],
        itemsTS: []
      },

      pageLoader: true,
    }
  },

  computed: {
    ...mapState({
      projectData: state => state.bi.comparison.project_data,
      metrics: state => state.bi.segments.metrics,
      segments: state => state.segments.catalog.segments,
      defaultSegments: state => state.bi.segments.defaultSegments,
      frequency: state => state.bi.comparison.frequency,
    }),

    ...mapGetters({
      segmentsListFrequencyGetter: 'bi/comparison/segmentsListFrequencyCorrelation',
      segmentItem: 'segments/catalog/segmentItem'
    }),

    /* ID проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.projectId
      return id ? +id : undefined
    },

    /* Флаг западного проекта */
    isWestProject () {
      try {
        return this.projectData.properties.is_west_project
      } catch (_) {
        return false
      }
    },

    /* Дефолтная поисковая система проекта */
    defaultSearchEngine () {
      try {
        return this.projectData.properties.default_search_engine
      } catch (_) {
        return false
      }
    },

    viewPage () {
      return !!this.activeProjectId && !!this.projectData.id
    },

    hasSegments () {
      try {
        const { category_id, group_id, query_group_id } = this.urlFilters
        return [].concat(category_id, group_id, query_group_id).filter(_ => _).length >= 1
      } catch (_) {
        return false
      }
    },

    /* Поисковые системы */
    searchEngines () {
      let list = [
        { id: 'google', name: this.$t('modules.searchEngines')['Google'] }
      ]
      if (!this.isWestProject) {
        list.unshift({ id: 'yandex', name: this.$t('modules.searchEngines')['Yandex'] })
      }
      return list
    },

    visibilities () {
      try {
        let visibilities = findOptions('visibility', this.metrics, 'name')
        let list = visibilities.items.map(item => {
          return { id: item.name, name: item.label }
        })
        return list
      } catch (_) {
        return []
      }
    },

    /* Фильтры из URL */
    urlFilters () {
      /* Инициализация URL фильтров */
      const initFilters = (query, options, required, defaultOption, cycle) => {
        if (cycle) {
          if (query) {
            /* Проверка наличия опций в фильтрах */
            if (query && Array.isArray(query)) {
              return query.map(f => +f).filter(f => f !== null && !isNaN(f) && f !== undefined)
            } else if ((typeof query === 'string' || typeof query === 'number') && !isNaN(+query)) {
              return [+query]
            } else {
              return defaultOption || []
            }
          } else {
            return defaultOption || []
          }
        } else {
          try {
            if (query && findOptions(query, options)) {
              return query
            } else if (required) {
              return defaultOption || options[0].id
            } else {
              return null
            }
          } catch (_) {
            return null
          }
        }
      }

      const initSegments = (query) => {
        let typeQuery = typeof query
        if (query && Array.isArray(query)) {
          return query
        } else if (typeQuery === 'string' || typeQuery === 'number') {
          return [+query]
        }
      }

      let { items, itemsTS } = this.updates
      const urlQuery = this.$route.query
      const filters = { date: {} }

      if (this.updates.has_data) {
        try {
          if (items && itemsTS && urlQuery.date_from && urlQuery.date_to) {
            let from = urlQuery.date_from
            let to = urlQuery.date_to
            let comparisonDates = +this.$moment.utc(from).format('x') <= +this.$moment.utc(to).format('x')
            if (items.indexOf(from) !== -1 && items.indexOf(to) !== -1 && comparisonDates) {
              filters.date.from = from
              filters.date.to = to
            } else {
              throw Error
            }
          } else {
            throw Error
          }
        } catch (_) {
          filters.date = this.generateQuartalPeriod({
            from: +this.$moment.utc(itemsTS[0]).subtract(3, 'month').format('x'),
            to: itemsTS[0]
          })
        }
      }

      filters.search_system = initFilters(urlQuery.search_system, this.searchEngines, true, this.defaultSearchEngine)
      filters.visibility = initFilters(urlQuery.visibility, this.visibilities, true, 'ptraf')
      filters.metric = initFilters(urlQuery.metric, this.salesMetrics, true, 'transactions')

      filters.category_id = initSegments(urlQuery.category_id)
      filters.group_id = initSegments(urlQuery.group_id)
      filters.query_group_id = initSegments(urlQuery.query_group_id)

      filters.all_site = urlQuery.all_site === 'true'
      filters.kg_category_id = initSegments(urlQuery.kg_category_id)
      filters.kg_group_id = initSegments(urlQuery.kg_group_id)
      filters.kg_query_group_id = initSegments(urlQuery.kg_query_group_id)

      return filters
    },

    /* Расширенные даты */
    extendedDates () {
      /* Количество дат, добавляемых с двух сторон к графику */
      const EXTENDED_COUNT = 20

      let { items } = this.updates
      let indexLast = items.length - 1
      let indexFrom = items.indexOf(this.urlFilters.date.from)
      let indexTo = items.indexOf(this.urlFilters.date.to)

      let extFrom = indexFrom >= indexLast - EXTENDED_COUNT ? indexLast : indexFrom + EXTENDED_COUNT
      let extTo = indexTo <= EXTENDED_COUNT ? 0 : indexTo - EXTENDED_COUNT

      return {
        date_from: items[extFrom],
        date_to: items[extTo]
      }
    },

    /* Активная метрика видимости */
    activeVisibilities () {
      return findOptions(this.urlFilters.visibility, this.visibilities) || {}
    },

    /* Активная метрика продаж */
    activeSalesMetric () {
      return findOptions(this.urlFilters.metric, this.salesMetrics) || {}
    },

    segmentsListFrequency () {
      /* Генерация массива сегментов с данными */
      let segmentsData = this.formattedListSegments.map(segment => {
        return this.segmentItem({
          id: segment.id,
          type: segment.type
        }) || segment
      })

      /* Добавление статистических данных */
      return this.segmentsListFrequencyGetter(segmentsData)
    },

    formattedListSegments () {
      try {
        let {
          category_id,
          group_id,
          query_group_id
        } = this.urlFilters

        return [
          { list: category_id, type: CONSTANTS.SEMANTIC_SECTIONS.CATEGORY },
          { list: group_id, type: CONSTANTS.SEMANTIC_SECTIONS.GROUP },
          { list: query_group_id, type: CONSTANTS.SEMANTIC_SECTIONS.QUERY_GROUP }
        ].reduce((list, ids, i) => {
          if (ids && ids.list && Array.isArray(ids.list)) {
            list = list.concat(ids.list.map(id => {
              return { id: +id, type: ids.type }
            }))
          }
          return list
        }, [])
      } catch (_) {
        return []
      }
    },

    salesMetrics () {
      let metrics = [
        {id: 'transactions', name: 'Транзакции'},
        {id: 'transaction_revenue', name: 'Выручка'},
        {id: 'conversion', name: 'Конверсия'}
      ]

      return metrics
    }
  },

  methods: {
    ...mapActions({
      getUpdates: 'semantics/getUpdates',
      getMetrics: 'bi/segments/getMetrics',
      getFrequency: 'bi/comparison/getFrequency',
      getDefaultSegments: 'bi/segments/getDefaultSegments',
      getProject: 'bi/comparison/getProject',
      getTraffic: 'bi/comparison/getTraffic',
    }),

    generateQuartalPeriod ({ from, to }) {
      let { itemsTS } = this.updates
      return {
        to: nearestDate({
          date: to,
          updates: itemsTS,
          returnFormat: CONSTANTS.DATE_FORMAT.BASE_FORMAT
        }),
        from: nearestDate({
          date: from,
          updates: itemsTS,
          returnFormat: CONSTANTS.DATE_FORMAT.BASE_FORMAT,
          past: true
        })
      }
    },

    setDefaultSettings () {
      if (!this.defaultSegments.has_data) return {}

      const ENTITY_TYPES = {
        CATEGORY: 'category',
        QUERIES: 'tag'
      }

      const defaultCategories = this.defaultSegments.items.filter(segment => segment.entity_type === ENTITY_TYPES.CATEGORY).map(segment => segment.entity_id)
      const defaultQueries = this.defaultSegments.items.filter(segment => segment.entity_type === ENTITY_TYPES.QUERIES).map(segment => segment.entity_id)

      return {
        category_id: defaultCategories,
        group_id: defaultQueries
      }
    },

    async checkFiltersSegments (callback) {
      let {
        date,
        visibility,
        category_id,
        group_id,
        query_group_id,
        kg_category_id,
        kg_group_id,
        kg_query_group_id
      } = this.urlFilters

      if (!query_group_id &&
       !group_id &&
       !category_id &&
       !kg_category_id &&
       !kg_group_id &&
       !kg_query_group_id
      ) {
        await this.getDefaultSegments({
          query: {
            project_id: this.activeProjectId,
            date_from: date.from,
            date_to: date.to,
            visibility,
            segment: 'category',
            skip_regionality: 1
          }
        })

        if (this.defaultSegments.has_data) {
          try {
            await this.updateUrl(this.setDefaultSettings())
          } catch (_) {
          }
        }
      } else {
        await callback()
      }
    },

    /* Загрузка фильтров метрики */
    async initMetrics () {
      const { date } = this.urlFilters

      if (date.from && date.to) {
        try {
          await this.getMetrics({
            query: {
              project_id: this.activeProjectId,
              skip_regionality: 1
            }
          })
        } catch ({message}) {
          this.$notify({type: 'error', title: message})
        }
      }
    },

    /* Загрузка фильтров календаря */
    async initUpdates () {
      try {
        let result = await this.getUpdates({
          query: {
            project_id: this.activeProjectId,
            search_system: this.urlFilters.search_system,
            skip_regionality: 1
          }
        })

        if (result && result.has_data && result.items && result.items.length) {
          this.$set(this, 'updates', {
            has_data: result.has_data,
            items: result.items.map(item => item.date),
            itemsTS: result.items.map(item => item.timestamp * 1000)
          })
        }
      } catch ({message}) {
        this.$notify({type: 'error', title: message})
      }
    }
  }
}
