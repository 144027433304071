<template>
  <li class="tree-node">
    <div class="tree-node__title" @click="toggleList" :style="`padding-left: ${level * 20}px`">
      <svg
        :class="['tree-node__arrow', { '_open': activeList } ]"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z" />
      </svg>

      {{ data.name }}
    </div>

    <ul v-if="showList" v-show="activeList">
      <template v-if="data.is_selection && data.segments">
        <v-segment
          class="tree-node"
          v-for="(segment, i) in data.segments"
          v-bind="{
            level: level + 1,
            data: segment,
            value: value,
            multiple
          }"
          :key="i"
          v-on:click="click($event)"
          v-on:open="open"
        />
      </template>

      <template v-else-if="data.items">
        <v-tree-node
          v-for="(item, i) in data.items"
          v-bind="{
            level: level + 1,
            data: item,
            value: value,
            multiple
          }"
          :key="i"
          v-on:click="click($event)"
          v-on:open="open"
        />
      </template>
    </ul>
  </li>
</template>

<script>
import segment from './segment.vue'

export default {
  name: 'v-tree-node',

  components: {
    'v-segment': segment
  },

  props: {
    /* Значение */
    data: {
      type: [Object, String, Number],
      default: null
    },

    /* Уровень вложенности */
    level: {
      type: Number,
      default: 1
    },

    /* Список выбранных опций */
    value: {
      type: [Array, Number, String]
    },

    /* Множественный выбор */
    multiple: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      activeList: false
    }
  },

  computed: {
    showList () {
      return (this.data.items && this.data.items.length) || (this.data.segments && this.data.segments.length)
    },
  },

  methods: {
    toggleList () {
      this.activeList ^= true
    },

    click(data) {
      this.$emit('click', data)
    },

    open () {
      if(!this.activeList) {
        this.activeList = true
        this.$emit('open')
      }
    }
  }
};
</script>
