var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('fragment',[_c('div',{staticClass:"dashboard-filters"},[_c('div',{staticClass:"dashboard-filters__line"},[(_vm.typeDynamics)?_c('div',{staticClass:"dashboard-filters__item"},[_c('s-select',_vm._b({on:{"input":function($event){return _vm.updateFilters('search_system', $event)}},model:{value:(_vm.filters.search_system),callback:function ($$v) {_vm.$set(_vm.filters, "search_system", $$v)},expression:"filters.search_system"}},'s-select',{
            options: _vm.searchEngines,
            insideLabel: _vm.$t(_vm.contentPathFilters)['Search system'],
            placeholder: _vm.$t(_vm.contentPathFilters)['Select SS'],
            minWidth: '0'
          },false))],1):_vm._e(),_c('div',{staticClass:"dashboard-filters__item"},[_c('s-select',_vm._b({on:{"input":function($event){return _vm.updateFilters('metric', $event)}},model:{value:(_vm.filters.metric),callback:function ($$v) {_vm.$set(_vm.filters, "metric", $$v)},expression:"filters.metric"}},'s-select',{
            options: _vm.salesMetrics,
            insideLabel: _vm.$t(_vm.contentPathFilters)['Sales'],
            placeholder: _vm.$t(_vm.contentPathFilters)['Select a metric'],
            minWidth: '0'
          },false))],1),_c('div',{staticClass:"dashboard-filters__item"},[_c('s-daterangepicker',_vm._b({on:{"input":function($event){return _vm.updateFilters('date', $event)}},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}},'s-daterangepicker',{
            format: _vm.CONSTANTS.DATE_FORMAT.BASE_FORMAT,
            placeholder: _vm.$t(_vm.contentPathFilters)['Select period'],
            dynamicWidthDrop: true,
            dropPosition: 'bottom-end',
            availables: _vm.updates.items,
            fastButtonDay: false
          },false))],1),_c('div',{staticClass:"dashboard-filters__item _right"},[_c('v-button-group',{attrs:{"options":_vm.types,"new-design":"","narrow":""}})],1)])]),_c('div',{staticClass:"dashboard-filters"},[_c('div',{staticClass:"dashboard-filters__line"},[_c('div',{staticClass:"dashboard-filters__item"},[_c('v-select-tree-segments',_vm._b({on:{"update-url":_vm.updateUrl}},'v-select-tree-segments',{
            filters: _vm.filters,
            insideLabel: _vm.$t(_vm.contentPathFilters)['Add segments'],
            placeholder: _vm.$t(_vm.contentPathFilters)['Select segment']
          },false))],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }